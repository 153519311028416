import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCartTotal } from "../../../services";
import { removeFromCartRevamp } from "../../../actions";
import { IMAGES_BASE_URL } from "../../../constants/api";
import { PRODUCT_DETAIL_PAGE } from "../../../constants/routes";
import { addResImagesHandler } from "../../../utils";

function CartMenuRevamp(props) {
	const { cartlist, removeFromCartRevamp, userData, capsuleNavigationCart } = props;
	let total = getCartTotal(cartlist);

	return (
		<div className={`${capsuleNavigationCart ? "dropdown cap-nav-cart-dropdown cart-dropdown mx-0" : "dropdown cart-dropdown mx-0"}`}>
			<Link
				to={`${process.env.PUBLIC_URL}/shop/cart`}
				className={`${capsuleNavigationCart ? "position-relative d-flex align-items-center h-100" : "dropdown-toggle"} `}
				role="button"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
				data-display="static">
				{!capsuleNavigationCart && <i className="fal fa-cart-arrow-down"></i>}
				{capsuleNavigationCart && (
					<img
						src={`${process.env.PUBLIC_URL}/assets/images/revamp/cart-icon.svg`}
						alt="card-icon"
					/>
				)}
				<span className="cart-count">{cartlist.length}</span>
				{!capsuleNavigationCart && <span className="cart-txt">Cart</span>}
			</Link>

			<div className={`dropdown-menu dropdown-menu-right ${cartlist.length === 0 ? "text-center" : ""}`}>
				{0 === cartlist.length ? (
					<p>No products in the cart.</p>
				) : (
					<>
						<div className="dropdown-cart-products">
							{(cartlist || []).map((item, index) => (
								<div
									className="product"
									key={item.id}>
									<div className="product-cart-details">
										<h4 className="product-title">
											<Link to={PRODUCT_DETAIL_PAGE(item && item.name, item && item.uuid)}>{item.name}</Link>
										</h4>

										<span className="cart-product-info">
											<span className="cart-product-qty">{item.qty}</span>x $
											{item.discount && item.salePrice < item.price
												? item && item.salePrice && item.salePrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
												: item && item.price && item.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
										</span>
									</div>

									<figure className="product-image-container">
										<Link
											to={PRODUCT_DETAIL_PAGE(item && item.name, item && item.uuid)}
											className="product-image">
											{item && item.pictures && item.pictures.length > 0 && item.pictures[0] ? (
												<img
													src={addResImagesHandler(item.pictures && item.pictures[0].path, "cart")}
													data-oi={IMAGES_BASE_URL + item.pictures[0].path}
													alt={item.name}
												/>
											) : (
												<img
													src={process.env.PUBLIC_URL + "/" + item.imagePlaceholder}
													data-oi={process.env.PUBLIC_URL + "/" + item.imagePlaceholder}
													alt={item.name}
												/>
											)}
										</Link>
									</figure>
									<button
										className="btn-remove"
										title="Remove Product"
										onClick={() => removeFromCartRevamp(item, userData.isLoggedIn ? userData.data.auth.access_token : undefined)}>
										<span className="fal fa-times"></span>
									</button>
								</div>
							))}
						</div>
						<div className="dropdown-cart-total">
							<span>Total</span>

							<span className="cart-total-price">${total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
						</div>

						<div className="dropdown-cart-action">
							<Link
								to={`${process.env.PUBLIC_URL}/shop/cart`}
								className="btn btn-primary">
								View Cart
							</Link>
							<Link
								to={`${process.env.PUBLIC_URL}/shop/checkout`}
								className="btn btn-outline-primary-2">
								<span>Checkout</span>
								<i className="fal fa-long-arrow-right"></i>
							</Link>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		userData: state.userData ? (state.userData.userData ? state.userData.userData : []) : [],
		cartlist: state.cartlist.cart_revamp ? state.cartlist.cart_revamp : [],
	};
}

export default connect(mapStateToProps, { removeFromCartRevamp })(CartMenuRevamp);
