import axios from "axios";
import store from "../store";
import { userLogin, userProfile } from "../actions";
import { toast } from "react-toastify";
import { API_URL, DOMAIN_URL } from "../constants/api";
import { useHistory } from "react-router-dom";
const headers = {
	"X-Requested-With": "XMLHttpRequest",
};

// API to get products from Admin server
export const getProducts = function (filters) {
	const data = {
		fields: [
			"id",
			"uuid",
			"owner_id",
			"name",
			"description",
			"condition",
			"category_id",
			"brand_id",
			"price",
			"is_taxable",
			"cost",
			"discount",
			"discount_unit",
			"age",
			"age_unit",
			"moq",
			"total_qty",
			"um",
			"msrp",
			"mpn",
			"weight",
			"weight_unit",
			"length",
			"length_unit",
			"width",
			"width_unit",
			"height",
			"height_unit",
			"package_type",
			"upc",
			"part_number",
			"sku",
			"last_sku",
			"type",
			"publish_date",
			"is_active",
			"reviews_total",
			"reviews_avg",
			"options",
			"variants",
			"scalling_price",
			"created_at",
		],
		with: ["images", "documents", "category", "brand", "owner"],
	};

	if (typeof filters !== "undefined") {
		if (typeof filters.price !== "undefined") {
			data.where = [
				["price", ">=", filters.price.min],
				["price", "<=", filters.price.max],
			];
		}
		data.where_in = [];
		if (filters.categories.length > 0) {
			data.where_in.push({
				field: "category_id",
				value: filters.categories,
			});
		}
		if (filters.brands.length > 0) {
			data.where_in.push({
				field: "brand_id",
				value: filters.brands,
			});
		}
		if (filters.sortby === "date") {
			data.orderby = {
				created_at: "DESC",
				name: "ASC",
			};
		} else if (filters.sortby === "rating") {
			data.orderby = {
				reviews_avg: "DESC",
				name: "ASC",
			};
		} else {
			data.orderby = {
				sale_count: "DESC",
				name: "ASC",
			};
		}
		if (filters.search !== "") {
			data.query = {
				value: "*" + filters.search + "*",
				fields: ["name"],
			};
		}
	}

	return axios
		.post(API_URL + "/v1/client/items/search", data, { headers: headers })
		.then(function (response) {
			let products = [];
			for (let i = 0; i < response.data.data.length; i++) {
				let product = response.data.data[i];
				let salePrice = product.price;
				let moq = product.moq;
				let bulkSavings = [];

				let condition = product.condition.charAt(0).toUpperCase() + product.condition.slice(1);

				if (product.scalling_price) {
					bulkSavings = product.bulk_savings;
				}
				if (product.discount && product.discount_unit === "percent") {
					salePrice = product.price - (product.price / 100) * product.discount;
				} else if (product.discount && product.discount_unit === "fixed") {
					salePrice = product.price - product.discount;
				}
				products.push({
					id: product.id,
					name: product.name,
					price: product.price,
					pictures: product.images,
					imagePlaceholder: "assets/images/products/shop/sm-1.jpg",
					shortDesc: product.description,
					stock: product.total_qty,
					category: [product.category ? product.category.name : ""],
					brands: [product.brand ? product.brand.name : ""],
					ratings: product.reviews_total,
					reviews: product.reviews_total,
					featured: true,
					shipping_type: product.shipping_type,
					age: product.age,
					age_unit: product.age_unit,
					brand_id: product.brand_id,
					category_id: product.category_id,
					condition: condition,
					main_category: product.category && product.category.parent_id,
					cost: product.cost,
					created_at: product.created_at,
					deleted_at: product.deleted_at,
					salePrice: salePrice,
					discount: product.discount,
					discount_unit: product.discount_unit,
					height: product.height,
					height_unit: product.height_unit,
					is_active: product.is_active,
					is_taxable: product.is_taxable,
					last_sku: product.last_sku,
					length: product.length,
					length_unit: product.length_unit,
					moq: moq,
					mpn: product.mpn,
					msrp: product.msrp,
					options: product.options,
					owner_id: product.owner_id,
					package_type: product.package_type,
					part_number: product.part_number,
					publish_date: product.publish_date,
					reviews_avg: product.reviews_avg,
					short_name: product.short_name,
					sku: product.sku,
					tags: product.tags,
					type: product.type,
					um: product.um,
					upc: product.upc,
					updated_at: product.updated_at,
					uuid: product.uuid,
					variants: product.variant,
					weight: product.weight,
					weight_unit: product.weight_unit,
					width: product.width,
					scalling_price: product.scalling_price,
					width_unit: product.width_unit,
					owner: product.owner,
					documents: product.documents,
					bulk_savings: bulkSavings,
				});
			}
			products = products && products.filter((product) => product.is_active !== false);
			return products;
		})
		.catch(function (error) {
			// handle error
		});
};

// API to get categories from Admin server
export const getCategories = function () {
	return axios
		.post(API_URL + "/v1/client/categories/search")
		.then(function (response) {
			let categories = [];
			for (let i = 0; i < response.data.data.length; i++) {
				let category = response.data.data[i];
				if (category.parent_id === null) {
					let sub_categories = [];
					for (let j = 0; j < response.data.data.length; j++) {
						if (response.data.data[j].parent_id === category.id) {
							sub_categories.push(response.data.data[j]);
						}
					}
					category = {
						id: category.id,
						name: category.name,
						description: category.description,
						sub_categories: sub_categories,
						has_items: category.has_items,
						parent_id: category.parent_id,
						is_active: category.is_active,
						created_at: category.created_at,
						updated_at: category.created_at,
					};
					categories.push(category);
				}
			}

			return categories;
		})
		.catch(function (error) {
			// handle error
		});
};

// API to get brands from Admin server
export const getBrands = function () {
	return axios
		.post(API_URL + "/v1/client/brands/search")
		.then(function (response) {
			return response.data.data;
		})
		.catch(function (error) {
			// handle error
		});
};

// API to get security-questions from Admin server
export const getSecurityQuestions = function () {
	return axios
		.get(API_URL + "/v1/client/security-questions")
		.then(function (response) {
			return response.data.data;
		})
		.catch(function (error) {
			// handle error
		});
};

// Api buyer signup
// x:moved into api/auth/register
export function signup(userData, callback) {
	try {
		axios
			.post(API_URL + "/v1/client/signup", userData)
			.then(function (response) {
				callback(response.data);
			})
			.catch(function (error) {
				// handle error
				toast.success(error.message);
			});
	} catch (error) {
		toast.success(error.message);
	}
}

// Api buyer signup
export function sellerSignup(userData, callback) {
	try {
		let data = {
			firstname: userData.firstname,
			// lastname : " ",
			email: userData.email,
			password: userData.password,
			password_confirmation: userData.password_confirmation,
			security_questions: JSON.stringify([
				{
					question: userData.question1,
					answer: userData.answer1,
				},
				{
					question: userData.question2,
					answer: userData.answer2,
				},
				{
					question: userData.question3,
					answer: userData.answer3,
				},
			]),
		};
		return axios
			.post(API_URL + "/v1/client/signup", data, { headers: headers })
			.then(function (response) {
				callback(response.data);
			})
			.catch(function (error) {
				// handle error
				toast.success(error.message);
			});
	} catch (error) {
		toast.success(error.message);
	}
}

// Api buyer StripeSubscription
export function stripeSubscription(paymentData, access_token, callback) {
	try {
		let data = {
			current_plan_id: 2,
			type: paymentData.card.object,
			token: paymentData.id,
			expiration_date: paymentData.card.exp_year,
			billing_zip: paymentData.card.address_zip,
			last_4: paymentData.card.last4,
			account_type: "business",
		};
		const config = {
			headers: { Authorization: `Bearer ${access_token}` },
		};
		headers.Authorization = "Bearer " + access_token;
		axios
			.post(API_URL + "/v1/client/subscriptions", data, config)
			.then(function (response) {
				callback(response.data);
			})
			.catch(function (error) {
				// handle error
				toast.success(error.message);
			});
	} catch (error) {
		toast.success(error.message);
	}
}

// Api seller PayoutAccounts
export function PayoutAccounts(userData, access_token, callback) {
	try {
		let data = {
			country: "us",
			business_name: userData.business_name,
			business_type: userData.business_type,
			business_website: userData.business_url,
			return_url: `${DOMAIN_URL}/my-sunhub/business-profile`,
		};
		const config = {
			headers: { Authorization: `Bearer ${access_token}` },
		};
		headers.Authorization = "Bearer " + access_token;
		axios
			.post(API_URL + "/v1/client/payout/accounts", data, config)
			.then(function (response) {
				callback(response.data);
				// if (typeof data.message === "undefined") {
				//
				//
				//     getOnBoardingURL(data.return_url, access_token, function (response2) {
				//

				//         callback(response2.data);
				//     });
				// }
			})
			.catch(function (error) {
				// handle error
				toast.success(error.message);
			});
	} catch (error) {
		toast.success(error.message);
	}
}

// Api seller getOnBoardingURL
export function getOnBoardingURL(return_url, access_token, callback) {
	try {
		const config = {
			headers: { Authorization: `Bearer ${access_token}` },
		};
		headers.Authorization = "Bearer " + access_token;
		axios
			.get(API_URL + "/v1/client/payout/my-account/get-onboarding-link?return_url=" + return_url, config)
			.then(function (response) {
				callback(response.data);
			})
			.catch(function (error) {
				// handle error
				toast.success(error.message);
			});
	} catch (error) {
		toast.success(error.message);
	}
}

// Api seller aboutBusiness
export function getLocation(id, access_token, callback) {
	try {
		const config = {
			headers: { Authorization: `Bearer ${access_token}` },
		};
		headers.Authorization = "Bearer " + access_token;
		axios
			.get(API_URL + `/v1/client/my-profile/addresses`, config)
			.then(function (response) {
				callback(response.data);
			})
			.catch(function (error) {
				// handle error
			});
	} catch (error) {}
}

// Api seller aboutBusiness
export function aboutBusiness(userData, access_token, callback) {
	try {
		const config = {
			headers: { Authorization: `Bearer ${access_token}` },
		};
		headers.Authorization = "Bearer " + access_token;
		axios
			.post(API_URL + "/v1/client/my-company", userData, config)
			.then(function (response) {
				callback(response.data);
			})
			.catch(function (error) {
				// handle error
				toast.success(error.message);
			});
	} catch (error) {
		toast.success(error.message);
	}
}

//Api seller/buyer login
// x:moved into api/auth/signin
export function login(userData, callback) {
	try {
		return axios
			.post(API_URL + "/v1/client/login", userData)
			.then(function (response) {
				callback(response.data);
			})
			.catch(function (error) {
				// handle error
				callback(error);
			});
	} catch (error) {
		toast.success(error.message);
	}
}

export function logOut() {
	localStorage.removeItem("userData");
	localStorage.removeItem("profile");
	store.dispatch(userLogin());
	store.dispatch(userProfile());
	let history = useHistory();
	history.push("/");
}
//
// function onUserNavigate() {
//     let idleTime = getCurrentTime() - getPreviousNavTime();
//     storeCurrentNavTime();
//     if (idleTime > ALLOWED_IDLE_TIME)
//         window.location.href = '#/security/logout';
// }

// API to get change-password from Admin server

export function changePassword(userData, access_token, callback) {
	try {
		const config = {
			headers: { Authorization: `Bearer ${access_token}` },
		};
		headers.Authorization = "Bearer " + access_token;
		axios
			.post(API_URL + "/v1/client/my-profile/change-password", userData, config)
			.then(function (response) {
				callback(response.data);
			})
			.catch(function (error) {
				// handle error
				toast.success(error.message);
			});
	} catch (error) {
		toast.success(error.message);
	}
}
export function forgotChangePassword(userData, access_token, callback) {
	const data = { ...userData, token: access_token };
	try {
		const config = {
			headers: { Authorization: `Bearer ${access_token}` },
		};
		headers.Authorization = "Bearer " + access_token;
		axios
			.post(API_URL + "/v1/client/password/reset", data, config)
			.then(function (response) {
				callback(response.data);
			})
			.catch(function (error) {
				// handle error
				toast.success("error.message");
				toast.success(error.message);
			});
	} catch (error) {
		toast.success(error.message);
	}
}
export function getProfile(access_token, callback) {
	try {
		const config = {
			headers: { Authorization: `Bearer ${access_token}` },
		};
		headers.Authorization = "Bearer " + access_token;
		axios
			.get(
				API_URL +
					'/v1/client/my-profile/edit?fields=["id", "firstname", "lastname", "email", "company", "avatar"]&' +
					'with=["company", "addresses", "subscription", "payout_account", "role"]',
				config
			)
			.then(function (response) {
				callback(response.data.data);
			})
			.catch(function (error) {
				// handle error
				toast.success(error.message);
			});
	} catch (error) {
		toast.success(error.message);
	}
}

// Api updateSecurityQuestions
export function updateSecurityQuestions(userData, access_token, callback) {
	try {
		let data = {
			security_questions: JSON.stringify([
				{
					question: userData.question1,
					answer: userData.answer1,
				},
				{
					question: userData.question2,
					answer: userData.answer2,
				},
				{
					question: userData.question3,
					answer: userData.answer3,
				},
			]),
		};
		const config = {
			headers: { Authorization: `Bearer ${access_token}` },
		};
		headers.Authorization = "Bearer " + access_token;
		return axios
			.put(API_URL + "/v1/client/my-profile/security-questions", data, config)
			.then(function (response) {
				callback(response.data);
			})
			.catch(function (error) {
				// handle error
				toast.success(error.message);
			});
	} catch (error) {
		toast.success(error.message);
	}
}

// Api buyer shippingAddress
export function shippingAddress(userData, access_token, callback) {
	try {
		const config = {
			headers: { Authorization: `Bearer ${access_token}` },
		};
		headers.Authorization = "Bearer " + access_token;
		axios
			.post(API_URL + "/v1/client/my-profile/addresses", userData, config)
			.then(function (response) {
				callback(response.data);
			})
			.catch(function (error) {
				// handle error
				toast.success(error.message);
			});
	} catch (error) {
		toast.success(error.message);
	}
}

// API to get 404 status cod3
export const getNotFoundStatus = function () {
	return axios
		.get(API_URL + "/v1/client/page-not-found")
		.then(function (response) {
			return response.data.data;
		})
		.catch(function (error) {
			// handle error
		});
};
