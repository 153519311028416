import { combineReducers } from "redux";

// Import custom components
import productReducer from "./products";
import categoryReducer from "./categories";
import userDataReducer from "./userData";
import brandsReducer from "./brands";
import cartReducer from "./cart";
import wishlistReducer from "./wishlist";
import compareReducer from "./compare";
import filterReducer from "./filter";
import modalReducer from "./modal";
import profileReducer from "./profile";
import storeFront from "./storefront";
import { sessionReducer } from "redux-react-session";
import addEditProductReducer from "./add-edit-product";
import broker from "./broker";
import GuestUser from "./guest.user";

const rootReducer = combineReducers({
	data: productReducer,
	cartlist: cartReducer,
	wishlist: wishlistReducer,
	compare: compareReducer,
	filters: filterReducer,
	modal: modalReducer,
	categories: categoryReducer,
	brands: brandsReducer,
	userData: userDataReducer,
	profile: profileReducer,
	addEditProduct: addEditProductReducer,
	session: sessionReducer,
	storeFront,
	broker,
	GuestUser,
});

export default rootReducer;
