import "react-toastify/dist/ReactToastify.min.css";
import React, { useEffect, useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { closeModal, migrateAllCartItems } from "../../../actions";
import Register from "../../forms/buyer/register";
import SignIn from "../../forms/buyer/signin";
import { register } from "../../../api/auth/register";
import { signin } from "../../../api/auth/signin";
import ForgotPasswordModal from "./forgot-password-modal";
import { forgotPassword } from "../../../api/auth/forgotPassword";
const customStyles = {
	content: {
		top: "50%",
		transform: "translateY(-50%)",
	},
	overlay: {
		backgroundColor: "rgba(77,77,77,0.6)",
		zIndex: "10000",
	},
};

Modal.setAppElement("#root");

function LoginModal(props) {
	const { showModal, modal } = props;
	const [showpop, setshowpop] = useState(false);
	let timer;

	function closeModal() {
		document.getElementById("login-modal").classList.remove("ReactModal__Content--after-open");
		timer = setTimeout(() => {
			props.closeModal("login");
		}, 200);
	}

	const loginHandler = (data) => {
		signin(data, toast, (result) => {
			if (result.status && result.status) {
				migrateAllCartItems(props.cartlist && props.cartlist, result.data.auth && result.data.auth.access_token);
				props.closeModal("login");
			}
		});
	};

	const registerHandler = (data) => {
		register(data, props, toast);
	};
	const forgotPasswordHandler = (data) => {
		forgotPassword(data, props, toast, closeform);
	};
	useEffect(() => {
		return () => {
			if (timer) clearTimeout(timer);
		};
	});

	const showfrompop = () => {
		closeModal();
		setshowpop(true);
	};

	const closeform = () => {
		setshowpop(false);
	};
	return (
		<Modal
			isOpen={showModal && "login" === modal}
			onRequestClose={closeModal}
			style={customStyles}
			contentLabel="Login Modal"
			className="modal-dialog modal-dialog-centered"
			id="login-modal">
			<div className="modal-content">
				<div className="modal-body">
					<button
						type="button"
						className="close"
						data-dismiss="modal"
						aria-label="Close"
						onClick={closeModal}>
						<span aria-hidden="true">
							<i className="fal fa-times"></i>
						</span>
					</button>
					<div className="form-box">
						<div className="form-tab">
							<Tabs
								selectedTabClassName="show"
								defaultIndex={props.modalState}>
								<TabList className="nav nav-pills nav-fill">
									<Tab className="nav-item">
										<span className="nav-link">Sign In</span>
									</Tab>

									<Tab className="nav-item">
										<span className="nav-link">Register</span>
									</Tab>
								</TabList>

								<div className="tab-content">
									<TabPanel style={{ paddingTop: "2rem" }}>
										<SignIn
											onSubmit={loginHandler}
											forgotPasswordModal={showfrompop}
										/>
									</TabPanel>

									<TabPanel>
										<Register onSubmit={registerHandler} />
									</TabPanel>
								</div>
							</Tabs>
						</div>
					</div>
				</div>
			</div>

			<ForgotPasswordModal
				showpop={showpop}
				closeform={closeform}
				onSubmit={forgotPasswordHandler}
			/>
		</Modal>
	);
}

function mapStateToProps(state) {
	return {
		cartlist: state.cartlist.cart,
		showModal: state.modal.showModal,
		modal: state.modal.modal,
		userData: state.userData ? state.userData : [],
		products: state.data.products ? state.data.products : [],
	};
}

export default connect(mapStateToProps, { closeModal })(LoginModal);
