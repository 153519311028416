import { POST } from "../api";
import { toast } from "react-toastify";
import { SOMETHING_WRONG } from "../../lang/en/messages";
import { CART_ITEM_LIST } from "../../constants/api";
import { USER, USER_PROFILE } from "../../constants/local-storage";
import store from "../../store";
import { userLogin, userProfile } from "../../actions";

export const getAllCartItem = async (query, onwer_id = "", callback, token) => {
	query = {
		fields: [
			"id",
			"item_uuid",
			"qty",
			"price",
			"shipping_type",
			"total_qty",
			"discount",
			"scalling_price",
			"shipping_cost",
		],
		with: ["item", "item.category", "item.brand", "item.bulk_savings", "item.images", "item.locations", "item.owner"],
		owner_id: onwer_id,
	};
	POST(
		CART_ITEM_LIST,
		query,
		function (data) {
			if (data && data.status === 1) {
				callback(data);
			} else {
				if (data.http_error) {
					callback(false);
				} else {
					if (data.errors) {
						if (typeof data.errors.owner_id !== "undefined") {
							toast.error("Session timeout please login again.");
							localStorage.removeItem(USER);
							localStorage.removeItem(USER_PROFILE);
							store.dispatch(userLogin());
							store.dispatch(userProfile());
							callback(false);
						}
						for (let i = 0; i < data.errors.length; i++) {
							toast.error(data.errors[i]);
						}
					} else {
						toast.error(SOMETHING_WRONG);
					}
					callback(false);
				}
			}
		},
		token
	);
};
