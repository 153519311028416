import React from "react";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";

const LoginModal = ({ loginModalVisible, setIsLoginModalVisible, userData }) => {
	const history = useHistory();
	const isUserSeller = userData.userData.isLoggedIn && userData.userData.data.role && userData.userData.data.role.includes("seller");
	/**
	 * For Later usage
			const isUserCustomer = userData.userData.isLoggedIn && userData.userData.data.role && userData.userData.data.role.includes("customer")
		*/

	const goToSellerCentral = () => {
		if (isUserSeller) {
			setIsLoginModalVisible(false);
			history.push(`${process.env.PUBLIC_URL}/my-sunhub/seller-overview/sell-an-item`);
		} else {
			setIsLoginModalVisible(false);
			history.push(`${process.env.PUBLIC_URL}/register-business/seller`);
		}
	};
	const goToTraderPlatform = () => {
		if (isUserSeller) {
			setIsLoginModalVisible(false);
			history.push(`${process.env.PUBLIC_URL}/trader/trader-deals`);
		} else {
			setIsLoginModalVisible(false);
			history.push(`${process.env.PUBLIC_URL}/trader/how-to-sell`);
			// history.push(`${process.env.PUBLIC_URL}/solar-trader-platform`);
		}
	};
	return (
		<>
			<Modal
				className="select-seller-modal"
				visible={loginModalVisible}
				centered
				onOk={() => setIsLoginModalVisible(false)}
				onCancel={() => setIsLoginModalVisible(false)}
				footer={null}
				width={768}
				closable={true}>
				<div className="login-wrapper">
					<div className="row">
						<div className="col-12">
							<div className="global-center-heading pt-0">
								<h1 className="title mb-0">If you are Selling</h1>
							</div>
						</div>
						<div className="col-md-6 mb-2 mb-md-0">
							<div className="login-container flex-vertical">
								<div className="">
									<div className="icon-block">
										<img
											className="user-icon"
											src={`${process.env.PUBLIC_URL}/assets/images/broker/login/user-icon.svg`}
											alt="Icon On Hover"
										/>
										<span className="show-on-hover">
											<img
												className="user-icon d-none"
												src={`${process.env.PUBLIC_URL}/assets/images/broker/login/icon-on-hover.svg`}
												alt="Icon On Hover"
											/>
										</span>
									</div>
									<ul className="offered-services-lists list-has-icons">
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Solar Panels PPW
										</li>
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Batteries & Inverters
										</li>
										<li className="has-sub-items">
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Have Negotiable Contract Terms
											<ul>
												<li>Incoterms</li>
												<li>Payment</li>
												<li>Delivery</li>
											</ul>
										</li>
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Want To Receive Bids
										</li>
									</ul>
								</div>
								<button
									onClick={goToTraderPlatform}
									// to="/broker/create-deal"
									className="btn btn-dark btn-lg py-3 btn-block">
									Go to Solar Trader Platform
								</button>
							</div>
						</div>
						<div className="col-md-6">
							<div className="login-container flex-vertical">
								<div className="">
									<div className="icon-block">
										<img
											className="user-with-money"
											src={`${process.env.PUBLIC_URL}/assets/images/broker/login/user-money.svg`}
											alt="Icon On Hover"
										/>
										<span className="show-on-hover">
											<img
												className="user-with-money d-none"
												src={`${process.env.PUBLIC_URL}/assets/images/broker/login/icon-on-hover-1.svg`}
												alt="Icon On Hover"
											/>
										</span>
									</div>
									<ul className="offered-services-lists list-has-icons">
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Everything else
										</li>
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Price per unit
										</li>
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Fixed Terms
										</li>
										<li>
											<span className="hoverable-icon">
												<img
													src={`${process.env.PUBLIC_URL}/assets/images/broker/login/sunhub-icon.svg`}
													alt="sunhub icon"
												/>
											</span>
											Fixed Price
										</li>
									</ul>
								</div>

								<button
									onClick={goToSellerCentral}
									className="btn btn-dark btn-lg py-3 btn-block">
									Go to Seller Central
								</button>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default LoginModal;
