import { toast } from "react-toastify";
import { SOMETHING_WRONG } from "../../lang/en/messages";
import { NEWS_LETTER } from "../../constants/api";
import { POST } from "../api";

export const newsletter = (email, callback, token = null) => {
	if (email) {
		var data = {
			email: email,
		};
		POST(
			NEWS_LETTER,
			data,
			function (data) {
				if (data.http_error) {
					toast.error(data.error);
				} else if (data.errors) {
					toast.error(data.errors.email[0]);
					callback(data);
				} else if (data.status && data.status) {
					toast.success(data.message);
					callback(data);
					// toast.success(`mail send to ${data.data.mail_send_to}`,);
				} else {
					toast.error(SOMETHING_WRONG);
				}
			},
			token
		);
	}
};
